import { z } from 'zod';

const includeCheckIn = 'checkIn';
const includeLease = 'lease';
const includeMonthly = 'monthlyStatement';

const feeConfigSchema = {
	value: z.number(),
	unit: z.string(),
	disabled: z.boolean().optional(),
};
const valuationSchema = z.object({
	coldWaterFee: z.object(feeConfigSchema),
	hotWaterFee: z.object(feeConfigSchema),
	electricityFee: z.object(feeConfigSchema),
	tvFee: z.object(feeConfigSchema),
	networkFee: z.object(feeConfigSchema),
	cleanFee: z.object(feeConfigSchema),
	storeColdWaterFee: z.object(feeConfigSchema),
	storeHotWaterFee: z.object(feeConfigSchema),
	storeElectricityFee: z.object(feeConfigSchema),
	storeTvFee: z.object(feeConfigSchema),
	storeNetworkFee: z.object(feeConfigSchema),
	storeCleanFee: z.object(feeConfigSchema),
	// 用来表述一页打印设置多少个
	detailCount: z.number().optional(),
});
const valuationSchemaV2 = z.object({
	coldWaterFee: z.object(feeConfigSchema),
	hotWaterFee: z.object(feeConfigSchema),
	electricityFee: z.object(feeConfigSchema),
	tvFee: z.object(feeConfigSchema),
	networkFee: z.object(feeConfigSchema),
	cleanFee: z.object(feeConfigSchema),
	// 用来表述一页打印设置多少个
	detailCount: z.number().optional(),
});

const defaultConfig = {
	coldWaterFee: {
		value: 5,
		unit: '\u5143/m\xB3',
		disabled: false,
	},
	hotWaterFee: {
		value: 25,
		unit: '\u5143/m\xB3',
		disabled: false,
	},
	electricityFee: {
		value: 1.2,
		unit: '\u5143/kW\xB7h',
		disabled: false,
	},
	tvFee: {
		value: 0,
		unit: '\u5143/\u6708',
		disabled: true,
	},
	networkFee: {
		value: 50,
		unit: '\u5143/\u6708',
		disabled: true,
	},
	cleanFee: {
		value: 20,
		unit: '\u5143/\u6708',
		disabled: true,
	},
	storeColdWaterFee: {
		value: 6,
		unit: '\u5143/m\xB3',
		disabled: false,
	},
	storeHotWaterFee: {
		value: 25,
		unit: '\u5143/m\xB3',
		disabled: false,
	},
	storeElectricityFee: {
		value: 1.5,
		unit: '\u5143/kW\xB7h',
		disabled: false,
	},
	storeTvFee: {
		value: 0,
		unit: '\u5143/\u6708',
		disabled: true,
	},
	storeNetworkFee: {
		value: 50,
		unit: '\u5143/\u6708',
		disabled: true,
	},
	storeCleanFee: {
		value: 20,
		unit: '\u5143/\u6708',
		disabled: true,
	},
	detailCount: 2,
};
function parseValuation(config) {
	const result = valuationSchema.safeParse(config);
	const resultV2 = valuationSchemaV2.safeParse(config);
	if (result.success) {
		return result.data;
	}
	if (!resultV2.success) {
		throw Error('\u697C\u5B87\u914D\u7F6E\u89E3\u6790\u51FA\u9519');
	}
	return resultV2.data;
}
function feeTips(a) {
	return `${a.value}${a.unit}`;
}

var roomStatusEnumeration = /* @__PURE__ */ ((roomStatusEnumeration2) => {
	roomStatusEnumeration2['busy'] = 'busy';
	roomStatusEnumeration2['open'] = 'open';
	return roomStatusEnumeration2;
})(roomStatusEnumeration || {});

var roomTypeEnumeration = /* @__PURE__ */ ((roomTypeEnumeration2) => {
	roomTypeEnumeration2['room'] = 'room';
	return roomTypeEnumeration2;
})(roomTypeEnumeration || {});

export {
	includeLease as a,
	includeMonthly as b,
	roomTypeEnumeration as c,
	defaultConfig as d,
	feeTips as f,
	includeCheckIn as i,
	parseValuation as p,
	roomStatusEnumeration as r,
};
