import dayjs from 'dayjs';
import { dateFormatShow } from '../constants';
import { calcFee } from '../util/calcFee';
import { parseValuation } from 'blog/type-file';
import { judge } from '../util/judge';
import type { RouterOutputs } from '../../../utils/trpc';

export const timeDayFormatDefine = 'YYYY-MM-DD';
export const timeFormatDefine = `${timeDayFormatDefine} HH:mm`;

export function listConvertFunc(data: RouterOutputs['apartment']['list']) {
	const nList = data.list.map((p) => {
		const f = data.feeConfigs.find(
			(j) => j.uuid === p.feeConfigSnapshotUuid,
		)?.feeConfig;
		const valuation = parseValuation(f);

		const logIndex = p?.logs?.length - 1;
		const addProps = {
			verify: judge(p),
			key: p.uuid,
			checkInTimeFormat: dayjs(p.checkInTime).format(dateFormatShow),
			checkOutTimeFormat: dayjs(p.checkOutTime).format(dateFormatShow),
			updateTime:
				p?.logs?.[logIndex]?.updateTime &&
				dayjs(p?.logs?.[logIndex]?.updateTime).format(timeFormatDefine),
			recordingTime:
				p?.logs?.[logIndex]?.recordingTime &&
				dayjs(p?.logs?.[logIndex]?.recordingTime).format(timeFormatDefine),

			operator: p?.logs?.[0]?.userId,
			logsCount: p.logs.length,
		};

		return { ...addProps, ...calcFee(p, valuation) };
	});

	return nList;
}
